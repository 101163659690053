






























































































































import { Vue, Component } from "vue-property-decorator";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import FooterComponent from "@/components/core/Footer.vue";
import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import VideoPlayerComponent from "@/components/media/VideoPlayer.vue";

// import VueIntercom from "vue-intercom";

import { LocaleService, ProductService, MetaService } from "@/services";
import { Media, ProductDetail } from "@/types";
import { getExploreMoreLink, blobDataPath } from "@/util";

@Component({
  components: {
    CityDarkBGComponent,
    VideoPlayerComponent,
    FooterComponent,
  },
})
export default class ProductDetailComponent extends Vue {
  private localeService = new LocaleService();
  private metaService = new MetaService();
  // public intercom = new VueIntercom({
  //   appId: process.env.VUE_APP_INTERCOM_APP_ID,
  // });

  public getExploreMoreLink = getExploreMoreLink;

  private xonReady = false;
  public get onReady(): boolean {
    return this.xonReady;
  }
  public set onReady(ready: boolean) {
    this.xonReady = ready;
  }

  private xproductData: ProductDetail | null = null;
  public get productData(): ProductDetail | null {
    return this.xproductData;
  }
  public set productData(product: ProductDetail | null) {
    this.xproductData = product;
  }

  private xshowPlayer = false;
  public get showPlayer(): boolean {
    return this.xshowPlayer;
  }
  public set showPlayer(show: boolean) {
    this.xshowPlayer = show;
  }

  public getMediaPath(media: Media): string {
    return (
      blobDataPath +
      (media.type === "video" ? media.poster.url : media.sources[0].url)
    );
  }

  public getSrcPath(url: string): string {
    return blobDataPath + url;
  }

  public mediaClick(media: Media | null): void {
    media && media.type === "video" ? (this.showPlayer = true) : null;
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private productService = new ProductService();

  private created(): void {
    if (!this.$route.params.name) {
      this.$router.push("city");
    }

    this.productService
      .getProductDataByName(this.$route.params.name)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((product: ProductDetail | undefined) => {
        if (product) {
          this.productData = product;
          if (
            product.metaDescription == undefined ||
            product.metaTitle == undefined
          ) {
            this.metaService.updateMetaDescription(product.description);
            this.metaService.updateMetaTitle(product.heading);
          } else {
            this.metaService.updateMetaDescription(product.metaDescription);
            this.metaService.updateMetaTitle(product.metaTitle);
          }
        }
      });
  }

  private mounted(): void {
    this.onReady = true;
    const locale = this.localeService.getLocale();
    // if (
    //   String(process.env.VUE_APP_ENABLE_INTERCOM) == "true" &&
    //   locale.code === "en"
    // ) {
    //   //wait 10 seconds
    //   setTimeout(() => {
    //     this.intercom.show();
    //   }, 10000);
    // }
  }

  private destroyed(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onImgLoad(): void {
    this.$emit("loaded", true);
  }
}
