var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-details-wrapper" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.onReady,
                expression: "onReady"
              }
            ],
            staticClass: "product-details-inner-wrapper"
          },
          [
            _vm.productData && _vm.productData.id
              ? _c(
                  "v-row",
                  { attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "product-content", attrs: { md: "5" } },
                      [
                        _c("h1", [_vm._v(_vm._s(_vm.productData.heading))]),
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.productData.description) + " "
                          )
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: {
                              text: "",
                              target: "_blank",
                              disabled: !_vm.productData.buttonLink,
                              href: _vm.getExploreMoreLink(_vm.productData)
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.productData.buttonTitle) + " "
                            ),
                            _vm.productData.buttonLink
                              ? _c("v-icon", { attrs: { color: "white" } }, [
                                  _vm._v(" arrow_forward ")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.productData.media.length === 1
                      ? _c(
                          "v-col",
                          { attrs: { md: "7" } },
                          [
                            _c(
                              "v-card",
                              { staticClass: "media-card" },
                              [
                                _c("v-hover", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var hover = ref.hover
                                          return [
                                            _vm.productData.media[0].type ===
                                            "video"
                                              ? [
                                                  _c(
                                                    "video",
                                                    {
                                                      staticClass:
                                                        "video-player",
                                                      attrs: {
                                                        controls: "",
                                                        autoplay: true,
                                                        poster: _vm.getSrcPath(
                                                          _vm.productData
                                                            .media[0].poster.url
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.productData.media[0]
                                                          .sources,
                                                        function(source) {
                                                          return _c("source", {
                                                            key: source.hash,
                                                            attrs: {
                                                              src: _vm.getSrcPath(
                                                                source.url
                                                              ),
                                                              type: source.mime
                                                            }
                                                          })
                                                        }
                                                      ),
                                                      _vm._v(
                                                        " Your browser doesn't support HTML5 video tag. "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              : [
                                                  _c("v-img", {
                                                    class: {
                                                      "video-poster-display--hovered": hover
                                                    },
                                                    attrs: {
                                                      src: _vm.getMediaPath(
                                                        _vm.productData.media[0]
                                                      )
                                                    },
                                                    on: {
                                                      load: _vm.onImgLoad,
                                                      click: function($event) {
                                                        return _vm.mediaClick(
                                                          _vm.productData
                                                            .media[0]
                                                        )
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "placeholder",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-sheet",
                                                                {
                                                                  staticClass:
                                                                    "px-3 pt-3 pb-3 fill-height d-flex",
                                                                  attrs: {
                                                                    color:
                                                                      "grey lighten-4"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-progress-circular",
                                                                    {
                                                                      staticClass:
                                                                        "progress",
                                                                      attrs: {
                                                                        size: 70,
                                                                        width: 7,
                                                                        color:
                                                                          "secondary",
                                                                        indeterminate:
                                                                          ""
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1292503444
                                                    )
                                                  })
                                                ]
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4203823133
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm.productData.media.length > 1
                      ? _c(
                          "v-col",
                          { attrs: { md: "7" } },
                          [
                            _c(
                              "v-carousel",
                              _vm._l(_vm.productData.media, function(media, i) {
                                return _c(
                                  "v-carousel-item",
                                  { key: i },
                                  [
                                    _c("v-hover", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var hover = ref.hover
                                              return [
                                                _c("v-img", {
                                                  class: {
                                                    "video-poster-display video-poster-display--xlarge":
                                                      media.type === "video",
                                                    "video-poster-display--hovered": hover
                                                  },
                                                  attrs: {
                                                    src: _vm.getMediaPath(media)
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.mediaClick(
                                                        media
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                            _c(
                              "v-card",
                              { staticClass: "media-card" },
                              [
                                _vm.showPlayer
                                  ? _c("VideoPlayerComponent", {
                                      attrs: {
                                        "player-data": _vm.productData.media[0]
                                      },
                                      on: {
                                        "player-closed": function($event) {
                                          _vm.showPlayer = false
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("FooterComponent")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }